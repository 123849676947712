import { useEffect, useState } from "react";
import { DeleteIcon } from "../helpers/svg";
import ClipboardJS from "clipboard";

const ShareLinks = ({ isOpen, setIsOpen }) => {
  const [url, setUrl] = useState("");
  const [vcf, setVcf] = useState("");
  const [copied, setCopied] = useState("");

  useEffect(() => {
    setUrl(window.location.href);
    setVcf(`${window.location.href}.vcf`);
  }, []);

  // Copy to clipboard
  const copy = (e) => {
    const copyId = e.target.id;
    new ClipboardJS(`#${copyId}`, {
      text: function () {
        return copyId === "url" ? url : vcf;
      },
    });
    setCopied(copyId);
  };

  useEffect(() => {
    const timer = setTimeout(() => setCopied(""), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  return (
    <div className="share">
      <div className={`share__box ${isOpen ? "open" : ""}`}>
        <div className="share__box-wrapper">
          <div className="share__box-heading">
            <h2>Share vCard</h2>
            <button
              className="share__box-close-btn"
              aria-label="close share links"
              onClick={() => setIsOpen(false)}
            >
              <DeleteIcon />
            </button>
          </div>

          <div className="share__box-content">
            <div className="field">
              <label htmlFor="web-url">Web URL</label>
              <input
                id="web-url"
                className="input"
                type="text"
                value={url}
                readOnly
              />
              <button
                id="url"
                className={`btn btn--${copied === "url" ? "green" : "primary"}`}
                onClick={copy}
              >
                {copied === "url" ? "Copied" : "Copy"}
              </button>
            </div>

            <div className="field">
              <label htmlFor="vcf-file">VCF File</label>
              <input
                id="vcf-file"
                className="input"
                type="text"
                value={vcf}
                readOnly
              />
              <button
                id="vcf"
                className={`btn btn--${copied === "vcf" ? "green" : "primary"}`}
                onClick={copy}
              >
                {copied === "vcf" ? "Copied" : "Copy"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinks;
