import React, { useEffect } from "react";
import { BackIcon, EmailSentIcon, LogoIcon } from "../helpers/svg";
import Button from "./Button";

const EmailSent = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <section className="email-sent">
      <div className="email-sent__content">
        <div className="email-sent__icon">
          <EmailSentIcon />
        </div>
        <h1>Check your mail</h1>
        <p>We have sent an email with instructions to reset your password.</p>
        <Button variant="primary" link="/login">
          <BackIcon />
          <span>Back to log in</span>
        </Button>
      </div>
      <footer className="email-sent__footer">
      <small>
          vCard powered by
          <a className="email-sent__footer-logo" href="https://nfc.com.eg/">
            <LogoIcon />
          </a>
        </small>
      </footer>
    </section>
  );
};

export default EmailSent;
