import Input from "./Input";
import SwitchButton from "../../components/SwitchButton";

const OccupationFields = ({ handleChange, values }) => {
  const occupation = values;
  return (
    <fieldset className="occupation">
      <div className="fieldset-container">
        <legend>Occupation</legend>
        <div className="inputs">
          <div>
            <Input
              label
              name="organization"
              fieldset="occupation"
              placeholder="organization name"
              onChange={handleChange}
              value={occupation.organization}
            />
            <Input
              label
              name="position title"
              fieldset="occupation"
              onChange={handleChange}
              value={occupation.positionTitle}
            />
          </div>
          <SwitchButton
            label="occupation"
            name="occupation.public"
            onChange={handleChange}
            value={occupation.public}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default OccupationFields;
