import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Button from "./Button";
import { AuthedUserContext } from "../store/authedUser";

const Uploader = () => {
  const { id } = useParams();
  const { authedUser } = useContext(AuthedUserContext);

  const [file, setFile] = useState();
  const [catalog, setCatalog] = useState();

  useEffect(() => {
    authedUser.id && getData(authedUser.id);
  }, [authedUser]);

  const getData = (id) => {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}`)
      .then((res) => {
        if (res.status === 404) {
          window.location.href = window.location.origin;
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        setCatalog(data.catalog);
        setFile(
          `${process.env.REACT_APP_S}/download-catalog/${data.catalog?.name}`
        );
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const downloadCatalog = () => {
    window.open(file, "_blank");
  };
  const viewCatalog = () => {
    let filePath = catalog.path.replace("public/", "");
    filePath = filePath.replace("public\\", "");
    window.open(`${process.env.REACT_APP_S}/${filePath}`, "_blank");
  };

  return (
    <div>
      {!!catalog && (
        <div className="catalog-view">
          <div>
            <div className="buttons">
              <div>
                <Button
                  type="button"
                  variant="primary"
                  onClick={downloadCatalog}
                >
                  Download catalog
                </Button>
              </div>
              <div>
                <Button type="button" variant="primary" onClick={viewCatalog}>
                  View catalog
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Uploader;
