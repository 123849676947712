import { toKebabCase, toCamelCase } from "../../helpers/functions.js";

const Input = ({
  name,
  onChange,
  value,
  fieldset,
  type,
  placeholder,
  label,
}) => (
  <>
    <input
      id={!label ? toKebabCase(name) : undefined}
      name={fieldset ? `${fieldset}.${toCamelCase(name)}` : toCamelCase(name)}
      type={type ? type : name.includes("password") ? "password" : "text"}
      placeholder={placeholder ? placeholder : name}
      aria-label={label && label.length >= 1 ? label : name}
      onChange={onChange}
      value={value}
    />
  </>
);

export default Input;
