import React from 'react'
import {BeatLoader} from "react-spinners"

const Loading = () => {
  return (
    <div className='loading'>
        <BeatLoader color="#ee4545" /> 
    </div>
  )
}

export default Loading