import React, { useState, useContext, useEffect } from "react";
import { AuthedUserContext } from "../store/authedUser";
import { IButton } from "../components/Button";

import SocialLink from "./SocialLink";
const SocialMedia = () => {
  const { configs } = useContext(AuthedUserContext);

  const openTutorial = () => window.open(configs.tutorialLink, "_blank");

  return (
    <ul className="social-media">
      <li>
        <SocialLink pf="website" link="https://nfc.com.eg" />
      </li>
      <li>
        <SocialLink pf="facebook" link="https://www.facebook.com/nfc.egypt/" />
      </li>
      <li>
        <SocialLink
          pf="instagram"
          link="https://www.instagram.com/nfc.egypt/"
        />
      </li>
      <li>
        <SocialLink pf="whatsapp" link="01015556784" />
      </li>
      <li>
        <SocialLink pf="twitter" link="https://twitter.com/NFC_Egypt" />
      </li>
      <li>
        <SocialLink pf="tiktok" link="https://www.tiktok.com/@nfc.egypt" />
      </li>
      <li>
        <IButton
          type="button"
          name="help"
          onClick={openTutorial}
          variant="secondary"
        ></IButton>
      </li>
    </ul>
  );
};

export default SocialMedia;
