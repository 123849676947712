import { useEffect, useState } from "react";
import {
  DownloadIcon,
  QrCodeIcon,
  SendIcon,
  ShareIcon,
} from "../helpers/svg";
import QrCode from "./QRCode";
import ShareLinks from "./ShareLinks";

const ProfileButtons = ({ id }) => {
  // List control
  const [list, setList] = useState(false);
  // Handling outside clicks
  useEffect(() => {
    list && setTimeout(() => window.addEventListener("click", closeList), 0);
    // Effects with cleanup function to clean event listener from window
    return () => window.removeEventListener("click", closeList);
  }, [list]);
  // Open
  const openList = () => setList(true);
  // close
  const closeList = () => setList(false);

  // QR Code control
  const [qrcodeIsOpen, setQrcodeIsOpen] = useState(false);
  // Handling outside clicks
  useEffect(() => {
    setTimeout(
      () => qrcodeIsOpen && window.addEventListener("click", closeQrcode),
      0
    );
    // Effects with cleanup function to clean event listener from window
    return () => window.removeEventListener("click", closeQrcode);
  }, [qrcodeIsOpen]);
  // Open
  const openQrcode = () => setQrcodeIsOpen(true);
  // close
  const closeQrcode = (e) =>
    e.target.classList.contains("qrcode__scan") && setQrcodeIsOpen(false);

  // Share Links control
  const [shareLinksIsOpen, setShareLinksIsOpen] = useState(false);
  // Handling outside clicks
  useEffect(() => {
    setTimeout(
      () =>
        shareLinksIsOpen && window.addEventListener("click", closeShareLink),
      0
    );
    // Effects with cleanup function to clean event listener from window
    return () => window.removeEventListener("click", closeShareLink);
  }, [shareLinksIsOpen]);
  // Open
  const openShareLink = () => setShareLinksIsOpen(true);
  // close
  const closeShareLink = (e) =>
    e.target.classList.contains("share__box") && setShareLinksIsOpen(false);

  return (
    <>
      <div className="card-profile__btn">
        <a
          className="btn btn--primary"
          role="button"
          href={`${window.location.origin}/card/${id}.vcf`}
          download
        >
          <DownloadIcon />
          Save Contact
        </a>
        <div className="list-share">
          <button
            className="icon btn btn--secondary list-share-btn"
            aria-label="share profile"
            onClick={openList}
          >
            <SendIcon />
          </button>
          <div className={`list-share-items ${list ? "open" : ""}`}>
            <ul className="list-share-items-wrapper">
              <li className="list-share-item">
                <button
                  className="btn btn--secondary icon"
                  aria-label="qr code"
                  onClick={openQrcode}
                >
                  <QrCodeIcon />
                  <span className="list-share-label">QR Code</span>
                </button>
              </li>
              <li className="list-share-item">
                <button
                  className="btn btn--secondary icon"
                  aria-label="share"
                  onClick={openShareLink}
                >
                  <ShareIcon />
                  <span className="list-share-label">Share Links</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <QrCode isOpen={qrcodeIsOpen} setIsOpen={setQrcodeIsOpen} />
      <ShareLinks isOpen={shareLinksIsOpen} setIsOpen={setShareLinksIsOpen} />
    </>
  );
};

export default ProfileButtons;
