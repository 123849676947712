import Select from "./Select";
import Input from "./Input";
import Button, { IButton } from "../../components/Button";
import SwitchButton from "../../components/SwitchButton";

const EmailsField = ({ handleChange, deleteItem, addItem, values }) => {
  const emails = values;
  return (
    <fieldset className="field">
      <div className="fieldset-container">
        <legend>
          {emails.length ? `Email${emails.length > 1 ? "s" : ""}` : ""}
        </legend>
        <div className="inputs">
          <ul className="list">
            {emails.map((email, index) => {
              return (
                <li key={index} className="item">
                  <Select
                    name={`emails[${index}].type`}
                    options={[
                      { value: "home" },
                      { value: "personal" },
                      { value: "work" },
                      { value: "business" },
                      { value: "other" },
                    ]}
                    onChange={handleChange}
                    value={email.type}
                  />
                  <Input
                    label
                    name="email"
                    fieldset={`emails[${index}]`}
                    onChange={handleChange}
                    value={email.email}
                  />
                  <div className="control">
                    <SwitchButton
                      label="email address"
                      name={`emails[${index}].public`}
                      onChange={handleChange}
                      value={email.public}
                    />
                    <IButton
                      name="delete"
                      title="Delete Email"
                      type="button"
                      onClick={() => deleteItem("emails", index)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => addItem("emails")}
          >
            Add Email
          </Button>
        </div>
      </div>
    </fieldset>
  );
};
export default EmailsField;
