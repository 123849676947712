import React from "react";
import Input from "./Input";
import SwitchButton from "../../components/SwitchButton";

const BdayDateFields = ({ handleChange, values, errorMsg }) => {
  const birthday = values;
  return (
    <fieldset
      className={`birthday ${errorMsg && errorMsg.birthday ? "error" : ""}`}
    >
      <div className="fieldset-container">
        <legend className="label" id="date">
          Birthday
        </legend>
        <div className="inputs" aria-labelledby="date" tabIndex={0}>
          <Input
            label
            name="day"
            fieldset="birthday"
            type="number"
            placeholder="DD"
            onChange={handleChange}
            value={birthday.day}
          />
          <Input
            label
            name="month"
            fieldset="birthday"
            type="number"
            placeholder="MM"
            onChange={handleChange}
            value={birthday.month}
          />
          <Input
            label
            name="year"
            fieldset="birthday"
            type="number"
            placeholder="YYYY"
            onChange={handleChange}
            value={birthday.year}
          />
          <SwitchButton
            label="birthday date"
            name="birthday.public"
            onChange={handleChange}
            value={birthday.public}
          />
          {errorMsg && errorMsg.birthday && (
            <span className="error-msg">{errorMsg.birthday}</span>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default BdayDateFields;
