import { useContext } from "react";
import { AuthedUserContext } from "../store/authedUser";
import Container from "../layout/Container";
import UserSettingForm from "../layout/forms/UserSettingForm";
import { Navigate, useLocation } from "react-router-dom";

const UserConfigCard = () => {
  const { authedUser } = useContext(AuthedUserContext);
  const { pathname } = useLocation();

  const notAuthorized =
    !authedUser ||
    (!(authedUser.isSuperAdmin || authedUser.isAdmin) &&
      !pathname.includes(authedUser.id));

  return notAuthorized ? (
    <Navigate to="/" />
  ) : !authedUser.contact ? (
    <Navigate to="/create-vcard" />
  ) : (
    <section className="edit-card fade-in">
      <Container>
        <UserSettingForm handle="edit_card" />
      </Container>
    </section>
  );
};

export default UserConfigCard;
