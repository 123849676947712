import {
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  TwitterIcon,
  WebsiteIcon,
  WhatsappIcon,
} from "../helpers/svg";

const SocialLink = ({ pf, link }) => {
  if (pf === "facebook") {
    !link.includes("facebook.com") &&
      !link.includes("http") &&
      (link = `https://facebook.com/${link}`);
  }
  if (pf === "instagram") {
    !link.includes("instagram.com") &&
      !link.includes("http") &&
      (link = `https://instagram.com/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (pf === "whatsapp") {
    link = `https://wa.me/${link}`;
  }
  if (pf === "twitter") {
    !link.includes("twitter.com") &&
      !link.includes("http") &&
      (link = `https://twitter.com/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (pf === "tiktok") {
    !link.includes("tiktok.com") &&
      !link.includes("http") &&
      (link = `https://tiktok.com/${link}`);
    !link.includes("@") && (link = link.replace(".com/", ".com/@"));
  }
  if (link && !link.includes("http")) {
    link = `https://${link}`;
  }

  return (
    <a href={link} target="_blank" rel="noreferrer" aria-label={`${pf} link`}>
      {pf === "facebook" ? (
        <FacebookIcon />
      ) : pf === "instagram" ? (
        <InstagramIcon />
      ) : pf === "whatsapp" ? (
        <WhatsappIcon />
      ) : pf === "twitter" ? (
        <TwitterIcon />
      ) : pf === "tiktok" ? (
        <TiktokIcon />
      ) : (
        <WebsiteIcon />
      )}
    </a>
  );
};

export default SocialLink;
