import Select from "./Select";
import Input from "./Input";
import Button, { IButton } from "../../components/Button";
import SwitchButton from "../../components/SwitchButton";

const WebsitesField = ({ handleChange, deleteItem, addItem, values }) => {
  const websites = values;
  return (
    <fieldset className="field">
      <div className="fieldset-container">
      <legend>
        {websites.length ? `Website${websites.length > 1 ? "s" : ""}` : ""}
      </legend>
      <div className="inputs">
      <ul className="list">
        {websites.map((website, index) => {
          return (
            <li key={index} className="item">
              <Select
                name={`websites[${index}].type`}
                options={[
                  { value: "home" },
                  { value: "work" },
                  { value: "other" },
                ]}
                onChange={handleChange}
                value={website.type}
              />
              <Input
                label
                name="url"
                fieldset={`websites[${index}]`}
                onChange={handleChange}
                value={website.url}
              />
              <div className="control">
                <SwitchButton
                  label="website"
                  name={`websites[${index}].public`}
                  onChange={handleChange}
                  value={website.public}
                />
                <IButton
                  name="delete"
                  title="Delete website"
                  type="button"
                  onClick={() => deleteItem("websites", index)}
                />
              </div>
            </li>
          );
        })}
      </ul>
      <Button
        variant="tertiary"
        type="button"
        onClick={() => addItem("websites")}
      >
        Add Website
      </Button></div></div>
    </fieldset>
  );
};
export default WebsitesField;
