import { useEffect, useState } from "react";
import SignUp from "../components/SignUp";
import Sign from "../components/Sign";
import SignIn from "../components/SignIn";
import { useLocation } from "react-router-dom";
import Container from "../layout/Container";
import phoneMockupImg from "../assets/phone-mockup.png";
import { NFCEgyptLogo } from "../helpers/svg";
import ForgetPassword from "../components/ForgetPassword";
import SocialMedia from "../components/SocialMedia";

const Home = () => {
  const { pathname } = useLocation();
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    showPhoneMockup();
    window.addEventListener("resize", showPhoneMockup);
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [pathname]);

  const showPhoneMockup = () =>
    window.innerWidth >= 992 || pathname === "/"
      ? setIsLargeScreen(true)
      : setIsLargeScreen(false);

  return (
    <section className="home" data-bg={isLargeScreen}>
      <Container>
        {isLargeScreen && (
          <picture className="home__phone-mockup fade-in-intro">
            <img src={phoneMockupImg} alt="" />
            <div className="home__phone-mockup-intro fade-out-intro">
              <div className="intro-mob">
                <NFCEgyptLogo />
              </div>
            </div>
          </picture>
        )}
        <div className="home__signs">
          {pathname === "/" && <Sign />}
          {pathname === "/sign-up" && <SignUp />}
          {pathname === "/login" && <SignIn />}
          {pathname === "/forget-password" && <ForgetPassword />}
        </div>
        <SocialMedia />
      </Container>
    </section>
  );
};

export default Home;
