import Select from "./Select";
import Input from "./Input";
import SwitchButton from "../../components/SwitchButton";
import Button, { IButton } from "../../components/Button";

const AddressesField = ({ handleChange, deleteItem, addItem, values }) => {
  const addresses = values;
  return (
    <fieldset className="field address">
      <div className="fieldset-container">
        <legend>
          {addresses.length ? `Address${addresses.length > 1 ? "es" : ""}` : ""}
        </legend>
        <div className="inputs">
          <ul className="list">
            {addresses.map((address, index) => {
              return (
                <li key={index} className="item">
                  <Select
                    name={`addresses[${index}].type`}
                    options={[
                      { value: "home" },
                      { value: "work" },
                      { value: "other" },
                    ]}
                    onChange={handleChange}
                    value={address.type}
                  />
                  <Input
                    label
                    name="name"
                    fieldset={`addresses[${index}]`}
                    onChange={handleChange}
                    value={address.name}
                  />
                  <div className="adr-group">
                    <Input
                      label
                      name="address"
                      fieldset={`addresses[${index}]`}
                      placeholder="apt street address"
                      onChange={handleChange}
                      value={address.address}
                    />
                    <div>
                      <Input
                        label
                        name="city"
                        fieldset={`addresses[${index}]`}
                        onChange={handleChange}
                        value={address.city}
                      />
                      <Input
                        label
                        name="country"
                        fieldset={`addresses[${index}]`}
                        onChange={handleChange}
                        value={address.country}
                      />
                      <Input
                        label
                        name="zip"
                        fieldset={`addresses[${index}]`}
                        placeholder="zip/post code"
                        onChange={handleChange}
                        value={address.zip}
                      />
                    </div>
                  </div>
                  <div className="control">
                    <SwitchButton
                      label="address"
                      name={`addresses[${index}].public`}
                      onChange={handleChange}
                      value={address.public}
                    />
                    <IButton
                      name="delete"
                      title="Delete address"
                      type="button"
                      onClick={() => deleteItem("addresses", index)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => addItem("addresses")}
          >
            Add Address
          </Button>
        </div>
      </div>
    </fieldset>
  );
};
export default AddressesField;
