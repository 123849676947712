import { useState, useEffect, useContext } from "react";
import FontPicker from "font-picker-react";
import Container from "../layout/Container";
import { useParams, useNavigate } from "react-router-dom";
import { AuthedUserContext } from "../store/authedUser";
import NotFoundUser from "../components/NotFoundUser";
import Loading from "../components/Loading";
import BioBox from "../components/BioBox";
import ProfileButtons from "../components/ProfileButtons";
import { DefaultAvatarIcon } from "../helpers/svg";
import Theme from "../components/Theme";
import ThemeIcons from "../components/ThemeIcons";
import CatalogView from "../components/CatalogView";

const initialState = {
  status: "idle",
  errorMsg: {},
  values: {
    id: "",
    authedEmail: "",
    cardName: "",
    contactName: {
      title: "",
      firstName: "",
      lastName: "",
      suffix: "",
    },
    birthday: {
      day: "",
      month: "",
      year: "",
      public: true,
    },
    occupation: {
      organization: "",
      positionTitle: "",
      public: true,
    },
    phones: [],
    emails: [],
    websites: [],
    socialMediaLinks: [],
    addresses: [],
    note: {
      text: "",
      public: true,
    },
    image: {
      avatar: false,
      cover: false,
    },
    bg: "",
    fg: "",
    ff: "",
  },
};

const Card = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notFound, setNotFound] = useState(false);
  const { authedUser } = useContext(AuthedUserContext);
  const [formState, setFormState] = useState(initialState);

  const contact = formState.values;

  const setContact = (values) => {
    setFormState((currState) => ({
      ...currState,
      values,
    }));
  };

  const setStatus = (stu) =>
    setFormState((currState) => ({
      ...currState,
      status: stu,
    }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = (id) => {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}`)
      .then((res) => {
        if (res.status === 404) {
          if (authedUser.id === id) {
            navigate("/create-vcard");
          } else {
            setNotFound(true);
          }
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        setTimeout(() => {
          setStatus("success");
        }, 300);
        if (data.id) {
          document.documentElement.style.setProperty("--custom-font", data.ff);
          setContact(data);
          setNotFound(false);
        }
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    setStatus("loading");
    getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Cover image
  const [cover, setCover] = useState("");
  useEffect(() => {
    const coverURL = `${process.env.REACT_APP_S}/image/${id}-cover`;
    contact.image.cover && setCover(coverURL);
  }, [contact.image.cover, cover, id]);

  return formState.status === "loading" || formState.status === "idle" ? (
    <Loading />
  ) : notFound ? (
    <NotFoundUser />
  ) : !Object.keys(contact).length ? null : (
    <section
      className={`card-profile custom-font fade-in ${cover ? "" : "no-cover"}`}
      style={{ backgroundColor: contact.bg, color: contact.fg }}
    >
      <Container>
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css?family=${formState.values.ff}%3Aregular&subset=latin&font-display=swap`}
        />

        <div className="card-profile__contact">
          <div className="profile-img">
            {cover && (
              <div className="cover">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${cover})`,
                  }}
                ></div>
              </div>
            )}
            <div className="avatar">
              <picture className="avatar-wrapper">
                {!contact.image.avatar && <DefaultAvatarIcon />}
                {contact.image.avatar && (
                  <img
                    src={`${process.env.REACT_APP_S}/image/${id}-avatar`}
                    alt="pic"
                  />
                )}
              </picture>
            </div>
          </div>
          <div className="card-profile__heading">
            <div>
              <h1 className="card-profile__heading-name">
                {`${contact.contactName.title || ""} 
                ${contact.contactName.firstName} 
                ${contact.contactName.lastName} 
                ${contact.contactName.suffix || ""}`}
              </h1>

              <div className="card-profile__heading-occupation">
                {!contact.occupation.public &&
                (!authedUser ||
                  (authedUser && authedUser.id !== contact.id)) ? null : (
                  <div className="card-profile__title">
                    {contact.occupation.positionTitle}
                  </div>
                )}
                {!contact.occupation.public &&
                (!authedUser ||
                  (authedUser && authedUser.id !== contact.id)) ? null : (
                  <div className="card-profile__org">
                    {contact.occupation.organization}
                  </div>
                )}
              </div>
            </div>
            <ProfileButtons id={id} />
            {!contact.note.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : contact.note.text && (
                  <div className="card-profile__note">
                    <BioBox text={contact.note.text} />
                  </div>
                )}
          </div>
        </div>
        {!contact.cardTheme ||
        (contact.cardTheme && contact.cardTheme === "DETAILS") ? (
          <Theme contact={contact} authedUser={authedUser} />
        ) : contact.cardTheme === "ICONS" ? (
          <ThemeIcons contact={contact} authedUser={authedUser} />
        ) : (
          <ThemeIcons contact={contact} authedUser={authedUser} red />
        )}
        <CatalogView />
      </Container>
    </section>
  );
};

export default Card;
