const Checkbox = ({ label, name, onChange, value }) => {
  return (
    <div className="checkbox">
      <input
        name={name}
        type="checkbox"
        checked={value}
        aria-label={label ? label : name}
        onChange={onChange}
      />
    </div>
  );
};
export default Checkbox;
  