import { useState, useEffect, useContext, useRef } from "react";
import { catchNumber } from "../../helpers/functions";
import { DefaultAvatarIcon } from "../../helpers/svg";
import Button, { IButton } from "../../components/Button";
import Field from "../../layout/forms/Field";
import ContactNameFields from "../../layout/forms/ContactNameField";
import BdayDateFields from "../../layout/forms/BdayDateField";
import OccupationFields from "../../layout/forms/OccupationField";
import NoteField from "../../layout/forms/NoteField";
import PhonesField from "../../layout/forms/PhonesField";
import EmailsField from "../../layout/forms/EmailsField";
import WebsitesField from "../../layout/forms/WebsitesField";
import SocialMediaField from "../../layout/forms/SocialMediaField";
import AddressesField from "../../layout/forms/AddressesField";
import fetch from "isomorphic-fetch";
import { checkValidDate, checkValidName } from "../../helpers/validation";
import { BeatLoader } from "react-spinners";
import ErrorMsg from "../../components/ErrorMsg";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import Select from "./Select";
import { Routes, Route, useParams } from "react-router-dom";

const initialState = {
  status: "",
  errorMsg: {},
  values: {
    id: "",
    authedEmail: "",
    cardName: "",
    cardTheme: "DETAILS",
    contactName: {
      title: "",
      firstName: "",
      lastName: "",
      suffix: "",
    },
    birthday: {
      day: "",
      month: "",
      year: "",
      public: true,
    },
    occupation: {
      organization: "",
      positionTitle: "",
      public: true,
    },
    phones: [],
    emails: [],
    websites: [],
    socialMediaLinks: [],
    addresses: [],
    note: {
      text: "",
      public: true,
    },
    image: {
      avatar: false,
      cover: false,
    },
  },
};

const CardForm = ({ handle }) => {
  let { id: paramId } = useParams();
  const authToken = Cookies.get("auth_token");
  const [activeUser, setActiveUser] = useState();
  const [formState, setFormState] = useState(initialState);
  const { status, values, errorMsg } = formState;
  const {
    id,
    cardName,
    cardTheme,
    contactName,
    birthday,
    occupation,
    phones,
    emails,
    websites,
    socialMediaLinks,
    addresses,
    note,
    image,
  } = values;

  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  const [selectedAvatarFile, setSelectedAvatarFile] = useState(null);
  const [selectedCoverFile, setSelectedCoverFile] = useState(null);
  const [previewAvatarUrl, setPreviewAvatarUrl] = useState(null);
  const [previewCoverUrl, setPreviewCoverUrl] = useState(null);
  const [withoutAvatar, setWithoutAvatar] = useState(false);
  const [withoutCover, setWithoutCover] = useState(false);
  const selectInputRef = useRef(null);
  const selectInputCoverRef = useRef(null);

  const handleFileSelect = (e) => {
    const selectedAvatarFile = e.target.files[0];
    if (selectedAvatarFile) {
      const fileSizeKB = (selectedAvatarFile.size / 1024).toFixed(2);
      const fileType = selectedAvatarFile.type;
      const fileError = (msg) => {
        setErrorLog(`Photo upload error: ${msg}`);
        e.target.value = null;
      };
      if (!fileType.includes("image")) {
        fileError("The file is not an image.");
      } else {
        if (
          fileType.includes("jpeg") ||
          fileType.includes("jpg") ||
          fileType.includes("png") ||
          fileType.includes("gif")
        ) {
          if (fileSizeKB > 256) {
            fileError("The maximum file size is 256 KB.");
          } else {
            setSelectedAvatarFile(selectedAvatarFile);
            setPreviewAvatarUrl(URL.createObjectURL(selectedAvatarFile));
            setFormState((currState) => ({
              ...currState,
              values: {
                ...currState.values,
                image: {
                  ...currState.values.image,
                  avatar: true,
                },
              },
            }));
            withoutAvatar && setWithoutAvatar(false);
          }
        } else {
          fileError("Only static file types jpeg, jpg, png, gif are allowed.");
        }
      }
    }
  };

  const handleCoverFileSelect = (e) => {
    const selectedCoverFile = e.target.files[0];
    if (selectedCoverFile) {
      const fileSizeKB = (selectedCoverFile.size / 1024).toFixed(2);
      const fileType = selectedCoverFile.type;
      const fileError = (msg) => {
        setErrorLog(`Photo upload error: ${msg}`);
        e.target.value = null;
      };
      if (!fileType.includes("image")) {
        fileError("The file is not an image.");
      } else {
        if (
          fileType.includes("jpeg") ||
          fileType.includes("jpg") ||
          fileType.includes("png") ||
          fileType.includes("webp") ||
          fileType.includes("gif")
        ) {
          if (fileSizeKB > 2000) {
            fileError("The maximum file size is 2 MB.");
          } else {
            setSelectedCoverFile(selectedCoverFile);
            setPreviewCoverUrl(URL.createObjectURL(selectedCoverFile));
            setFormState((currState) => ({
              ...currState,
              values: {
                ...currState.values,
                image: {
                  ...currState.values.image,
                  cover: true,
                },
              },
            }));
            withoutCover && setWithoutCover(false);
          }
        } else {
          fileError(
            "Only static file types jpeg, jpg, png, webp, gif are allowed."
          );
        }
      }
    }
  };

  const handleRemoveAvatar = () => {
    if (selectedAvatarFile || previewAvatarUrl || image.avatar) {
      setWithoutAvatar((prev) => !prev);
      formState.values.image.avatar = withoutAvatar;
    }
  };

  const handleRemoveCover = () => {
    if (selectedCoverFile || previewCoverUrl || image.cover) {
      setWithoutCover((prev) => !prev);
      formState.values.image.cover = withoutCover;
    }
  };

  useEffect(() => {
    activeUser && image.avatar && handleImageUrl(`/image/${id}-avatar`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleImageUrl = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], "image.jpg", { type: "image/jpeg" });
    setSelectedAvatarFile(file);
  };

  useEffect(() => {
    errorMsg && (errorMsg.contactName || errorMsg.birthday)
      ? setFormState((currState) => ({
          ...currState,
          errors: true,
        }))
      : setFormState((currState) => ({
          ...currState,
          errors: false,
        }));
  }, [errorMsg, setFormState]);

  useEffect(() => {
    if (handle === "create_contact") {
      setFormState((currState) => ({
        ...currState,
        values: {
          ...currState.values,
          id: activeUser.id,
          authedEmail: activeUser.email,
        },
      }));
      setStatus("idle");
    } else {
      getData(paramId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, handle, setFormState]);

  const getData = (id) => {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}`)
      .then((res) => {
        if (res.status === 404) {
          window.location.href = window.location.origin;
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        setFormState((currState) => ({
          ...currState,
          values: data,
        }));
        // setActiveUser((currState) => ({
        //   ...currState,
        //   values: data,
        // }));
        setStatus("idle");
      })
      .catch((err) => console.log(err, "err"));
  };

  const handleSubmitCreateContact = (e) => {
    e.preventDefault();
    setStatus("loading");

    if (validation()) {
      setErrorMsg({});
      const formData = new FormData();
      formData.append("values", JSON.stringify(formState.values));
      selectedAvatarFile &&
        image.avatar &&
        formData.append("avatar", selectedAvatarFile);
      selectedCoverFile && formData.append("cover", selectedCoverFile);
      sendDataCreated(formData);
    } else {
      setStatus("errors");
    }
  };
  const sendDataCreated = (formData) => {
    // post values to the server
    fetch(`${process.env.REACT_APP_S}/api/create-contact`, {
      method: "POST",
      headers: { authorization: authToken },
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          setStatus("idle");
          window.open(`${window.location.origin}/card/${id}`, "_blank");
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data, "userRegister");
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };

  const handleSubmitEditCard = (e) => {
    e.preventDefault();
    setStatus("loading");
    console.log("selectedAvatarFile: ", selectedAvatarFile);
    console.log("selectedCoverFile: ", selectedCoverFile);
    if (validation()) {
      const formData = new FormData();
      formData.append("values", JSON.stringify(formState.values));
      selectedAvatarFile &&
        image.avatar &&
        formData.append("avatar", selectedAvatarFile);
      selectedCoverFile && formData.append("cover", selectedCoverFile);
      console.log("form ", formData);
      sendDataEdit(formData);
    } else {
      setStatus("errors");
    }
  };

  const sendDataEdit = (formData) => {
    // post values to the server
    fetch(`${process.env.REACT_APP_S}/api/edit-contact/${id}`, {
      method: "PUT",
      headers: { authorization: authToken },
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          // setStatus("success");
          // setActiveUser((currState) => ({
          //   ...currState,
          //   action: !currState.action ? 0 : currState.action + 1,
          // }));
          setStatus("idle");
          window.open(`/card/${id}`, "_blank");
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userRegister");
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };
  const deleteItem = (field, indexDelete) =>
    setFormState((currState) => ({
      ...currState,
      values: {
        ...currState.values,
        [field]: currState.values[field].filter(
          (_, index) => index !== indexDelete
        ),
      },
    }));

  const addItem = (field) => {
    let item = {};
    field === "phones" && (item = { type: "MAIN", number: "", public: true });
    field === "emails" && (item = { type: "HOME", email: "", public: true });
    field === "websites" && (item = { type: "HOME", url: "", public: true });
    field === "socialMediaLinks" &&
      (item = { type: "FACEBOOK", url: "", public: true });
    field === "addresses" &&
      (item = {
        type: "HOME",
        name: "",
        address: "",
        city: "",
        country: "",
        zip: "",
        public: true,
      });
    setFormState((currState) => ({
      ...currState,
      values: {
        ...currState.values,
        [field]: [...currState.values[field], item],
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Limit character length
    if (name === "birthday.month" || name === "birthday.day")
      `${value}`.length <= 2 && setValues(name, value);
    else if (name === "birthday.year")
      `${value}`.length <= 4 && setValues(name, value);
    else setValues(name, value);

    // Check input if there is an error
    status === "errors" && inputChecker(name, value);
  };
  const inputChecker = (name, value) => {
    const { month, day, year } = values.birthday;

    if (name === "birthday.day")
      setErrorMsg("birthday", checkValidDate(value, month, year));
    if (name === "birthday.month")
      setErrorMsg("birthday", checkValidDate(day, value, year));
    if (name === "birthday.year")
      setErrorMsg("birthday", checkValidDate(day, month, value));
    if (name === "contactName.firstName")
      setErrorMsg("contactName", checkValidName(value, contactName.lastName));
  };
  const setErrorMsg = (name, msg) =>
    setFormState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  // Form data validation
  const validation = () => {
    formState.errorMsg = {
      contactName: checkValidName(values.contactName.firstName),
      birthday: checkValidDate(birthday.day, birthday.month, birthday.year),
    };
    return checkErrors(formState.errorMsg) ? false : true;
  };

  const checkErrors = (errorMsg) => {
    let errors = false;
    Object.values(errorMsg).forEach((value) => {
      if (value) errors = true;
    });
    return errors;
  };

  const setStatus = (stu) =>
    setFormState((currState) => ({
      ...currState,
      status: stu,
    }));

  const setValues = (name, value) => {
    const keyName = name.split("."),
      isNested = keyName.length > 1,
      isInArray = catchNumber(name),
      arrayKey = isInArray && keyName[0].replace(`[${isInArray}]`, ""),
      isCheckbox = keyName[1] === "public",
      isArrayCheckbox = arrayKey && isCheckbox;

    isArrayCheckbox
      ? setFormState((currState) => ({
          ...currState,
          values: {
            ...currState.values,
            [arrayKey]: currState.values[arrayKey].map((obj, index) =>
              index === +isInArray
                ? {
                    ...obj,
                    [keyName[1]]: obj.public ? false : true,
                  }
                : obj
            ),
          },
        }))
      : isCheckbox
      ? setFormState((currState) => ({
          ...currState,
          values: {
            ...currState.values,
            [keyName[0]]: {
              ...currState.values[keyName[0]],
              [keyName[1]]: currState.values[keyName[0]].public ? false : true,
            },
          },
        }))
      : isInArray
      ? setFormState((currState) => ({
          ...currState,
          values: {
            ...currState.values,
            [arrayKey]: currState.values[arrayKey].map((obj, index) =>
              index === +isInArray
                ? {
                    ...obj,
                    [keyName[1]]: value,
                  }
                : obj
            ),
          },
        }))
      : isNested
      ? setFormState((currState) => ({
          ...currState,
          values: {
            ...currState.values,
            [keyName[0]]: {
              ...currState.values[keyName[0]],
              [keyName[1]]: value,
            },
          },
        }))
      : setFormState((currState) => ({
          ...currState,
          values: {
            ...currState.values,
            [keyName[0]]: value,
          },
        }));
  };
  return !status ? (
    <Loading />
  ) : (
    <>
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <form
        className="card-form"
        onSubmit={
          handle === "create_contact"
            ? handleSubmitCreateContact
            : handleSubmitEditCard
        }
      >
        <div className="card-form__group--main">
          {/* Avatar */}
          <div className="profile-img">
            <div className="cover">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    !withoutCover
                      ? previewCoverUrl
                        ? previewCoverUrl
                        : image.cover
                        ? `${process.env.REACT_APP_S}/image/${id}-cover`
                        : ""
                      : ""
                  })`,
                }}
              ></div>
              <input
                ref={selectInputCoverRef}
                type="file"
                onChange={handleCoverFileSelect}
                style={{ display: "none" }}
              />
              <div className="select-cover-button">
                <IButton
                  name="select img"
                  title="Select an img"
                  type="button"
                  onClick={() => selectInputCoverRef.current.click()}
                />
                {image.cover && (
                  <IButton
                    // name={withoutCover ? "restore" : "remove"}
                    name="remove"
                    title="Remove image"
                    type="button"
                    onClick={handleRemoveCover}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Contact */}
          <div className="card-form__main-info">
            <div className="card-form__avatar">
              <picture className="avatar-wrapper">
                {(!image.avatar || withoutAvatar) && <DefaultAvatarIcon />}
                {!withoutAvatar && image.avatar && (
                  <img
                    src={
                      previewAvatarUrl
                        ? previewAvatarUrl
                        : `${process.env.REACT_APP_S}/image/${id}-avatar`
                    }
                    alt="pic"
                  />
                )}
                <input
                  ref={selectInputRef}
                  type="file"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
              </picture>
              <div className="card-form__avatar-btn">
                <IButton
                  name="select img"
                  title="Select an img"
                  type="button"
                  onClick={() => selectInputRef.current.click()}
                />
                <IButton
                  name={withoutAvatar ? "restore" : "remove"}
                  title="Remove image"
                  type="button"
                  onClick={handleRemoveAvatar}
                />
              </div>
            </div>
            <div className="card-form__group--body-fields">
              <div className="card-form__group--body-fields-card">
                <Field
                  label="card info"
                  name="card name"
                  onChange={handleChange}
                  value={cardName}
                />
                <div className="field">
                  <label htmlFor="theme">Theme</label>
                  <Select
                    id="theme"
                    name={`cardTheme`}
                    options={[
                      { value: "details" },
                      { value: "icons" },
                      { value: "red icons" },
                    ]}
                    onChange={handleChange}
                    value={cardTheme}
                  />
                </div>
              </div>
              <ContactNameFields
                handleChange={handleChange}
                values={contactName}
                errorMsg={errorMsg}
              />
            </div>
          </div>
        </div>

        {/* Body */}
        <div className="card-form__group--body">
          <BdayDateFields
            handleChange={handleChange}
            values={birthday}
            errorMsg={errorMsg}
          />
          <OccupationFields handleChange={handleChange} values={occupation} />
          <PhonesField
            handleChange={handleChange}
            deleteItem={deleteItem}
            addItem={addItem}
            values={phones}
          />
          <EmailsField
            handleChange={handleChange}
            deleteItem={deleteItem}
            addItem={addItem}
            values={emails}
          />
          <WebsitesField
            handleChange={handleChange}
            deleteItem={deleteItem}
            addItem={addItem}
            values={websites}
          />
          <SocialMediaField
            handleChange={handleChange}
            deleteItem={deleteItem}
            addItem={addItem}
            values={socialMediaLinks}
          />
          <AddressesField
            handleChange={handleChange}
            deleteItem={deleteItem}
            addItem={addItem}
            values={addresses}
          />
          <NoteField handleChange={handleChange} values={note} />
          <Button
            variant="primary"
            type="submit"
            disabled={status === "loading" ? true : false}
          >
            {status === "loading" ? (
              <BeatLoader color="#ffffff" />
            ) : handle === "create_contact" ? (
              "Create Card"
            ) : (
              "Update & Save"
            )}
          </Button>
        </div>
        {errorMsg.contactName || errorMsg.birthday ? (
          <div className="errors">
            <ul>
              {Object.values(errorMsg).map(
                (err, index) => err && <li key={index}>{err}</li>
              )}
            </ul>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default CardForm;
