import { useState, useEffect, useContext } from "react";
import Button from "../../components/Button";
import { AuthedUserContext } from "../../store/authedUser";
import fetch from "isomorphic-fetch";
import ErrorMsg from "../../components/ErrorMsg";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import { StatisticsIcon } from "../../helpers/svg";
import { Routes, Route, useParams } from "react-router-dom";
import Field from "../forms/Field";
import Input from "../forms/Input";

const initialState = {
  status: "",
  errorMsg: {},
  values: {
    allowedUserNo: 0,
  },
};

const CardForm = ({ handle }) => {
  const authToken = Cookies.get("auth_token");
  let { id: paramId } = useParams();

  const { authedUser, setAuthedUser } = useContext(AuthedUserContext);
  const [formState, setFormState] = useState(initialState);
  const { status, values, errorMsg } = formState;
  const { allowedUserNo } = values;

  const [user, setUser] = useState(false);
  const [stats, setStats] = useState(false);
  useEffect(() => {
    authedUser ? getData() : setUser({ message: "Unauthorized" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    fetch(`${process.env.REACT_APP_S}/api/page-data/user-setting/${paramId}`, {
      headers: { authorization: authToken },
    })
      .then((data) => data.json())
      .then((data) => {
        setUser(data.user);
        setStats(data.statistics);
        setStatus("idle");
      });
  };

  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  const [bg, setBg] = useState("#ffffff");

  useEffect(() => {
    errorMsg && (errorMsg.contactName || errorMsg.birthday)
      ? setFormState((currState) => ({
          ...currState,
          errors: true,
        }))
      : setFormState((currState) => ({
          ...currState,
          errors: false,
        }));
  }, [errorMsg, setFormState]);

  useEffect(() => {
    if (handle === "create_contact") {
      setFormState((currState) => ({
        ...currState,
        values: {
          ...currState.values,
          id: authedUser.id,
          authedEmail: authedUser.email,
        },
      }));
      setStatus("idle");
    } else {
      authedUser.id && getData(authedUser.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedUser, handle, setFormState]);

  useEffect(() => {
    setFormState((currState) => ({
      ...currState,
      values: {
        ...currState.values,
        allowedUserNo: user.allowedUserNo,
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSave = function (e) {
    e.preventDefault();
    setStatus("loading");

    fetch(`${process.env.REACT_APP_S}/api/admin/update-user-config`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: authToken,
      },
      body: JSON.stringify({
        id: user._id,
        allowedUserNo,
      }),
    })
      .then(async (res) => ({
        res,
        data: await res.json(),
      }))
      .then(({ res, data }) => {
        // debugger;
        setStatus("idle");
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormState((currState) => {
      return {
        ...currState,
        values: {
          ...currState.values,
          [name]: value,
        },
      };
    });

    // Check input if there is an error
    // status === "errors" && inputChecker(name, value);
  };

  const setStatus = (stu) =>
    setFormState((currState) => ({
      ...currState,
      status: stu,
    }));

  return !status ? (
    <Loading />
  ) : (
    <>
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <div>
        <h1>
          <StatisticsIcon /> {user.email}
        </h1>
        <h2>Current Users: {stats.users.count}</h2>
        <h2>Current Contacts: {stats.contacts.count}</h2>
        {authedUser.isAdmin && (
          <div className="dashboard">
            <Button variant="primary" link="/create-account" className="fit">
              Create new account
            </Button>
          </div>
        )}
      </div>

      <br></br>
      <br></br>
      <form onSubmit={handleSave}>
        <div className="field">
          <label>Maximum Number of Users Allowed</label>
          <Input
            label
            name="allowedUserNo"
            type="number"
            placeholder="Enter a number"
            onChange={handleChange}
            value={allowedUserNo}
          />
        </div>

        <br></br>

        <Button
          variant="primary"
          type="submit"
          disabled={status === "loading" ? true : false}
        >
          save
        </Button>

        {errorMsg.contactName || errorMsg.birthday ? (
          <div className="errors">
            <ul>
              {Object.values(errorMsg).map(
                (err, index) => err && <li key={index}>{err}</li>
              )}
            </ul>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default CardForm;
