import { useState, useEffect, useContext } from "react";
import { AuthedUserContext } from "../store/authedUser";
import { Link, NavLink, useLocation } from "react-router-dom";
import Container from "./Container";
import {
  ListIcon,
  DeleteIcon,
  CardIcon,
  EditIcon,
  OutIcon,
  NFCIcon,
  CreateIcon,
  NFCEgyptLogoIcon,
  StatisticsIcon,
  SettingsIcon,
} from "../helpers/svg";
import Button from "../components/Button";
import { DefaultAvatarIcon } from "../helpers/svg";
import NFC from "../components/nfc/NFC";
import Cookies from "js-cookie";

const adminId =
  "F5Ga9tzoHWgs1ju0cXRhghnaqXSgDTEbyAIJHejxMCgZviFVQ2uPKyNPiDLt6B6xyyId3saSgtJj91fPtfeXSeVsYWFRTiqrrvf";
const adminId2 =
  "gwSjLA49ZzwvQjjF1PlsSZ5KW0L5if0KicYOkmDwP0xAzv2S7DnyGUgjUQiq0xyqRl2GeIEfJF023JUtVocm2XmDh1JTFB3EjRG";
const adminId3 =
  "6DoFPCwOvD56KQjf6IcRjfrPvOuPVRoa0t7h8jmO17w0c5JnNbuJZhJB4RfKcTA7FQ1JvmB105IVxzlf4rCTPRJ9n4zZBx4CrD9";

const Header = () => {
  const { authedUser } = useContext(AuthedUserContext);

  const [menu, setMenu] = useState(false);
  const handleToggleMenu = () => setMenu((prev) => !prev);
  const handleCloseMenu = () => setMenu(false);

  useEffect(() => {
    menu
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [menu]);

  const [isHome, setIsHome] = useState(true),
    { pathname } = useLocation();
  useEffect(
    () =>
      setIsHome(
        pathname === "/" ||
          pathname === "/sign-up" ||
          pathname === "/login" ||
          pathname === "/forget-password" ||
          pathname.includes("/reset-password")
          ? true
          : false
      ),
    [pathname]
  );

  // Logout
  const logout = () => {
    Cookies.remove("auth_token");
    setTimeout(() => window.location.reload(false), 0);
  };

  // NFC BOX
  const [nfc, setNfc] = useState(false);
  // Handling outside clicks
  useEffect(() => {
    setTimeout(() => nfc && window.addEventListener("click", closeNfc), 0);
    // Effects with cleanup function to clean event listener from window
    return () => window.removeEventListener("click", closeNfc);
  }, [nfc]);
  // close
  const closeNfc = (e) => e.target.classList.contains("nfc") && setNfc(false);

  const checkIOSDevice = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  };
  return (
    <>
      {!isHome && (
        <header className="header">
          <Container>
            <div className="header__logo">
              <Link to="/" aria-label="NFC Egypt Cards">
                {/* <NFCEgyptLogoIcon /> cards */}
                vCard
              </Link>
            </div>
            {pathname === "/create-vcard" && (
              <div className="header__nav-item">
                <button className="header__nav-item-logout" onClick={logout}>
                  <OutIcon /> Logout
                </button>
              </div>
            )}
            {pathname !== "/create-vcard" && (
              <>
                <button
                  className="header__toggle-btn"
                  aria-label="menu-list"
                  onClick={handleToggleMenu}
                >
                  {menu ? <DeleteIcon /> : <ListIcon />}
                </button>

                <div className={`header__menu-list ${menu ? "open" : ""}`}>
                  <Container>
                    {authedUser && (
                      <div className="header__user-list">
                        <div className="header__user-info">
                          {authedUser.contact ? (
                            <>
                              <div className="header__user-avatar">
                                <picture className="avatar-wrapper">
                                  {authedUser.details.image &&
                                  authedUser.details.image.avatar ? (
                                    <img
                                      src={`${process.env.REACT_APP_S}/image/${authedUser.id}-avatar`}
                                      alt="pic"
                                    />
                                  ) : (
                                    <DefaultAvatarIcon />
                                  )}
                                </picture>
                              </div>
                              <div>
                                <div className="header__user-name">
                                  {`${authedUser.details.fname} ${authedUser.details.lname}`}
                                </div>
                                <div className="header__user-email">
                                  {authedUser.email}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="header__registered">
                              <div className="header__registered-hd">
                                You are registered with:
                              </div>
                              <div className="header__user-email">
                                {authedUser.email}
                              </div>
                            </div>
                          )}
                        </div>

                        <nav onClick={handleCloseMenu}>
                          <ul className="header__nav-list">
                            {authedUser.contact ? (
                              <>
                                {!checkIOSDevice() && window.NDEFReader && (
                                  <li className="header__nav-item">
                                    <button
                                      className="header__nav-item-nfc"
                                      onClick={() => setNfc(true)}
                                    >
                                      <NFCIcon /> Activate your NFC Card
                                    </button>
                                  </li>
                                )}
                                <li className="header__nav-item">
                                  <NavLink
                                    reloadDocument={
                                      !pathname.includes(authedUser.id)
                                        ? true
                                        : false
                                    }
                                    className={`header__nav-item-card ${({
                                      isActive,
                                    }) => (isActive ? "active" : "")}`}
                                    to={`/card/${authedUser.id}`}
                                    end
                                  >
                                    <CardIcon /> my card
                                  </NavLink>
                                </li>
                                <li className="header__nav-item">
                                  <NavLink
                                    className={`header__nav-item-edit ${({
                                      isActive,
                                    }) => (isActive ? "active" : undefined)}`}
                                    to={`/card/${authedUser.id}/edit`}
                                    end
                                  >
                                    <EditIcon /> Edit Card
                                  </NavLink>
                                </li>
                                <li className="header__nav-item">
                                  <NavLink
                                    className={`header__nav-item-edit ${({
                                      isActive,
                                    }) => (isActive ? "active" : undefined)}`}
                                    to={`/settings`}
                                    end
                                  >
                                    <SettingsIcon /> Settings
                                  </NavLink>
                                </li>
                                {authedUser.isSuperAdmin && (
                                  <li className="header__nav-item">
                                    <NavLink
                                      className={`header__nav-item-edit ${({
                                        isActive,
                                      }) => (isActive ? "active" : undefined)}`}
                                      to={`/configs`}
                                      end
                                    >
                                      <SettingsIcon /> Configs
                                    </NavLink>
                                  </li>
                                )}
                                {(authedUser.isSuperAdmin ||
                                  authedUser.isAdmin) && (
                                  <li className="header__nav-item">
                                    <NavLink
                                      className={`header__nav-item-edit ${({
                                        isActive,
                                      }) => (isActive ? "active" : undefined)}`}
                                      to={`/statistics`}
                                      end
                                    >
                                      <StatisticsIcon /> statistics
                                    </NavLink>
                                  </li>
                                )}
                              </>
                            ) : (
                              <li className="header__nav-item">
                                <NavLink
                                  className={`header__nav-item-create ${({
                                    isActive,
                                  }) => (isActive ? "active" : undefined)}`}
                                  to={`/create-vcard`}
                                >
                                  <CreateIcon /> Create card
                                </NavLink>
                              </li>
                            )}
                            <li className="header__nav-item">
                              <button
                                className="header__nav-item-logout"
                                onClick={logout}
                              >
                                <OutIcon /> Logout
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}

                    {!authedUser && (
                      <div className="header__sign">
                        <div className="header__sign-heading">
                          <h2>Create your vCard</h2>
                          <p>
                            Create your own vCard and share it with your friends
                            and family.
                          </p>
                        </div>
                        <div className="header__sign-btn">
                          <Button
                            variant="primary"
                            link="/sign-up"
                            onClick={handleCloseMenu}
                          >
                            Create vCard
                          </Button>
                          <Button
                            variant="secondary"
                            link="/login"
                            onClick={handleCloseMenu}
                          >
                            Login
                          </Button>
                        </div>
                      </div>
                    )}
                  </Container>
                </div>
              </>
            )}
          </Container>
        </header>
      )}
      {nfc && <NFC setNfcBox={setNfc} />}
    </>
  );
};

export default Header;
