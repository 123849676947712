import Select from "./Select";
import Input from "./Input";
import SwitchButton from "../../components/SwitchButton";
import Button, { IButton } from "../../components/Button";

const SocialMediaField = ({ handleChange, deleteItem, addItem, values }) => {
  const socialMediaLinks = values;
  return (
    <fieldset className="field">
      <div className="fieldset-container">
        <legend>{socialMediaLinks.length ? "Social Media" : ""}</legend>
        <div className="inputs">
          <ul className="list">
            {socialMediaLinks.map((socialMediaLink, index) => {
              return (
                <li key={index} className="item">
                  <Select
                    name={`socialMediaLinks[${index}].type`}
                    options={[
                      { value: "facebook" },
                      { value: "instagram" },
                      { value: "whatsapp" },
                      { value: "wa business" },
                      { value: "twitter" },
                      { value: "youtube" },
                      { value: "tiktok" },
                      { value: "linkedin" },
                      { value: "telegram" },
                      { value: "snapchat" },
                      { value: "other" },
                    ]}
                    onChange={handleChange}
                    value={socialMediaLink.type}
                  />
                  <Input
                    label
                    name="url"
                    fieldset={`socialMediaLinks[${index}]`}
                    onChange={handleChange}
                    value={socialMediaLink.url}
                    placeholder={
                      socialMediaLink.type === "WHATSAPP" || socialMediaLink.type === "WHATS BUSINESS"
                        ? "phone number"
                        : socialMediaLink.type === "OTHER"
                        ? "URL"
                        : "URL / Username / (+20) Phone Number"
                    }
                  />
                  <div className="control">
                    <SwitchButton
                      label="link"
                      name={`socialMediaLinks[${index}].public`}
                      onChange={handleChange}
                      value={socialMediaLink.public}
                    />
                    <IButton
                      name="delete"
                      title="Delete link"
                      type="button"
                      onClick={() => deleteItem("socialMediaLinks", index)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => addItem("socialMediaLinks")}
          >
            Add Social Media Link
          </Button>
        </div>
      </div>
    </fieldset>
  );
};
export default SocialMediaField;
