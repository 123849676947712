import Select from "./Select";
import Input from "./Input";
import Button, { IButton } from "../../components/Button";
import SwitchButton from "../../components/SwitchButton";

const PhonesField = ({ handleChange, deleteItem, addItem, values }) => {
  const phones = values;
  return (
    <fieldset className="field">
      <div className="fieldset-container">
        <legend>
          {phones.length ? `Phone${phones.length > 1 ? "s" : ""}` : ""}
        </legend>
        <div className="inputs">
          <ul className="list">
            {phones.map((phone, index) => {
              return (
                <li key={index} className="item">
                  <Select
                    name={`phones[${index}].type`}
                    options={[
                      { option: "main", value: "pref" },
                      { value: "home" },
                      { value: "personal" },
                      { value: "work" },
                      { value: "business" },
                      { value: "cell" },
                      { value: "fax" },
                      { value: "other" },
                    ]}
                    onChange={handleChange}
                    value={phone.type}
                  />
                  <Input
                    label
                    name="number"
                    fieldset={`phones[${index}]`}
                    onChange={handleChange}
                    value={phone.number}
                  />
                  <div className="control">
                    <SwitchButton
                      label="phone number"
                      name={`phones[${index}].public`}
                      onChange={handleChange}
                      value={phone.public}
                    />
                    <IButton
                      name="delete"
                      title="Delete Phone Number"
                      type="button"
                      onClick={() => deleteItem("phones", index)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => addItem("phones")}
          >
            Add Phone
          </Button>
        </div>
      </div>
    </fieldset>
  );
};
export default PhonesField;
