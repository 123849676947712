import { LogoIcon } from "../helpers/svg";
import Container from "./Container";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <small>
          vCard powered by
          <a className="footer__logo" href="https://nfc.com.eg/">
            <LogoIcon />
          </a>
        </small>
      </Container>
    </footer>
  );
};

export default Footer;
