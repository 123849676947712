import { ExclamationIcon } from "../../helpers/svg";
import Button from "../Button";

const NFCError = ({ errorBox, closeErrorBox, errorMsg }) => {
  return (
    <div className={`nfc__error ${errorBox ? "open" : ""}`}>
      <div className="nfc__error-wrapper">
        <div className="nfc__error-msg-wrapper">
          <span className="nfc__error-icon"><ExclamationIcon /></span>
          <span className="nfc__error-msg">{errorMsg}</span>
        </div>
        <div className="nfc__error-button">
          <Button variant="primary" onClick={closeErrorBox}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NFCError;
