import React from "react";
import {
  AddressIcon,
  BDayIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SnapchatIcon,
  TelegramIcon,
  TelephoneIcon,
  TiktokIcon,
  TwitterIcon,
  WebsiteIcon,
  WhatsappBusinessIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "../helpers/svg";
import { formatDate, usernameToURL, trimURL } from "../helpers/functions";

const Theme = ({ contact, authedUser }) => {
  return (
    <ul className="card-profile__contacts">
      {contact.phones.length
        ? contact.phones.map((phone, index) =>
            !phone.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : phone.number && (
                  <li key={index} className="card-profile__contacts-item">
                    <div className="card-profile__contacts-item-icon">
                      <TelephoneIcon />
                    </div>
                    <a
                      href={`tel:${phone.number}`}
                      className="card-profile__contacts-item-link"
                    >
                      {phone.number}
                    </a>
                    {!phone.public && (
                      <span className="card-profile__contacts-item-private">
                        (private)
                      </span>
                    )}
                  </li>
                )
          )
        : null}

      {contact.emails.length
        ? contact.emails.map((email, index) =>
            !email.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : email.email && (
                  <li key={index} className="card-profile__contacts-item">
                    <div className="card-profile__contacts-item-icon">
                      <EmailIcon />
                    </div>
                    <a
                      href={`mailto:${email.email}`}
                      className="card-profile__contacts-item-link"
                    >
                      {email.email}
                    </a>
                    {!email.public && (
                      <span className="card-profile__contacts-item-private">
                        (private)
                      </span>
                    )}
                  </li>
                )
          )
        : null}

      {contact.websites.length
        ? contact.websites.map((website, index) => {
            if (website.url && !website.url.includes("http")) {
              website.url = `https://${website.url}`;
            }
            return !website.public &&
              (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : website.url && (
                  <li key={index} className="card-profile__contacts-item">
                    <div className="card-profile__contacts-item-icon">
                      <WebsiteIcon />
                    </div>
                    <a
                      href={website.url}
                      target="_blank"
                      rel="noreferrer"
                      className="card-profile__contacts-item-link"
                    >
                      {trimURL(website.url)}
                    </a>
                    {!website.public && (
                      <span className="card-profile__contacts-item-private">
                        (private)
                      </span>
                    )}
                  </li>
                );
          })
        : null}

      {contact.socialMediaLinks.length
        ? contact.socialMediaLinks.map((link, index) => {
            const type = link.type;
            const url = usernameToURL(type, link.url);
            return !link.public &&
              (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : url && (
                  <li key={index} className="card-profile__contacts-item">
                    <div className="card-profile__contacts-item-icon">
                      {type === "FACEBOOK" ? (
                        <FacebookIcon />
                      ) : type === "INSTAGRAM" ? (
                        <InstagramIcon />
                      ) : type === "WHATSAPP" ? (
                        <WhatsappIcon />
                      ) : type === "WA BUSINESS" ? (
                        <WhatsappBusinessIcon />
                      ) : type === "TWITTER" ? (
                        <TwitterIcon />
                      ) : type === "TIKTOK" ? (
                        <TiktokIcon />
                      ) : type === "YOUTUBE" ? (
                        <YoutubeIcon />
                      ) : type === "LINKEDIN" ? (
                        <LinkedinIcon />
                      ) : type === "TELEGRAM" ? (
                        <TelegramIcon />
                      ) : type === "SNAPCHAT" ? (
                        <SnapchatIcon />
                      ) : (
                        <WebsiteIcon />
                      )}
                    </div>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="card-profile__contacts-item-link"
                    >
                      {trimURL(url)}
                    </a>
                    {!link.public && (
                      <span className="card-profile__contacts-item-private">
                        (private)
                      </span>
                    )}
                  </li>
                );
          })
        : null}

      {contact.addresses.length
        ? contact.addresses.map((address, index) =>
            !address.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : (address.address ||
                  address.city ||
                  address.country ||
                  address.zip) && (
                  <li key={index} className="card-profile__contacts-item adr">
                    <div className="card-profile__contacts-item-icon">
                      <AddressIcon />
                    </div>
                    <div className="card-profile__contacts-item-text">
                      <span className="card-profile__contacts-item-label">
                        {`${address.type.toLowerCase()} ${
                          address.name && `- ${address.name}`
                        }`}
                      </span>
                      <span className="card-profile__contacts-item-value">
                        {`${address.address} ${address.city} ${address.country} ${address.zip}`}
                        {!address.public && (
                          <span className="card-profile__contacts-item-private">
                            (private)
                          </span>
                        )}
                      </span>
                    </div>
                  </li>
                )
          )
        : null}

      {!contact.birthday.public &&
      (!authedUser || (authedUser && authedUser.id !== contact.id))
        ? null
        : contact.birthday.month && (
            <li className="card-profile__contacts-item">
              <div className="card-profile__contacts-item-icon">
                <BDayIcon />
              </div>
              <div className="card-profile__contacts-item-text">
                {formatDate(
                  `${contact.birthday.month}/${contact.birthday.day}/${contact.birthday.year}`
                )}
                {!contact.birthday.public && (
                  <span className="card-profile__contacts-item-private">
                    (private)
                  </span>
                )}
              </div>
            </li>
          )}
    </ul>
  );
};

export default Theme;
