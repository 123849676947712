import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Button from "./Button";
import { Routes, Route, useParams } from "react-router-dom";

const Uploader = () => {
  let { id } = useParams();

  const [file, setfile] = useState();
  const [catalog, setCatalog] = useState();

  useEffect(() => {
    getData(id);
  }, []);

  const getData = (id) => {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}`)
      .then((res) => {
        if (res.status === 404) {
          window.location.href = window.location.origin;
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        setCatalog(data.catalog);
      })
      .catch((err) => console.log(err, "err"));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("myfile", file[0]);

    axios
      .post(`${process.env.REACT_APP_S}/api/upload-catalog/${id}`, formData, {
        headers: { authorization: Cookies.get("auth_token") },
      })
      .then((response) => {
        setCatalog(response.data);
      });
  };

  const downloadCatalog = () => {
    window.open(`${process.env.REACT_APP_S}/download-catalog/${catalog.name}`);
  };
  const removeCatalog = () => {
    axios
      .delete(`${process.env.REACT_APP_S}/api/remove-catalog/${id}`, {
        headers: { authorization: Cookies.get("auth_token") },
      })
      .then(() => {
        setCatalog(null);
      });
  };

  const onChange = (e) => {
    setfile(e.target.files);
  };

  return (
    <div className="uploader">
      <form onSubmit={(e) => onFormSubmit(e)}>
        <h4>PDF file</h4>
        {catalog && (
          <div>
            <div className="buttons">
              <div>
                <Button
                  type="button"
                  variant="primary"
                  onClick={downloadCatalog}
                >
                  Download catalog
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={removeCatalog}
                >
                  Remove catalog
                </Button>
              </div>
            </div>
          </div>
        )}
        {!catalog && (
          <div className="row">
            <div className="input">
              <input
                type="file"
                className="file-input"
                accept="application/pdf"
                name="myImage"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="button">
              <button className="btn btn--primary" type="submit">
                Upload
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Uploader;
