import { useEffect, useContext, useState } from "react";
import { AuthedUserContext } from "../store/authedUser";
import Button from "./Button";

const Sign = () => {
  const { authedUser } = useContext(AuthedUserContext);
  const [loginRoute, setLoginRoute] = useState("/login");
  useEffect(() => {
    authedUser && setLoginRoute(`/card/${authedUser.id}`);
  }, [authedUser]);
  return (
    <section className="home__sign fade-in">
      <h1>Create your digital business card</h1>
      <p>
        vCard enables you to send contact information in a format that can be
        easily read in other devices.
      </p>
      <div className="home__sign-buttons">
        <Button variant="primary" link="/sign-up">
          Sign Up
        </Button>
        <Button variant="secondary" link={loginRoute}>
          Already have an account? Login
        </Button>
      </div>
    </section>
  );
};

export default Sign;
