import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthedUserContext } from "../store/authedUser";
import uid from "../helpers/uid";
import { BackIcon } from "../helpers/svg";
import Field from "../layout/forms/Field";
import Button from "./Button";
import {
  checkValidCPassword,
  checkValidEmail,
  checkValidPassword,
} from "../helpers/validation";
import ErrorMsg from "./ErrorMsg";
import { BeatLoader } from "react-spinners";
import Cookies from "js-cookie";

const SignUp = () => {
  const { setAuthedUser } = useContext(AuthedUserContext);
  const navigate = useNavigate();
  const authToken = Cookies.get("auth_token");

  useEffect(() => {
    setState((currState) => ({
      ...currState,
      values: {
        ...currState.values,
        id: uid(),
      },
    }));
  }, []);

  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  const [state, setState] = useState({
    status: "idle",
    errorMsg: {},
    values: {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { id, email, firstName, lastName, password, confirmPassword } =
    state.values;
  const { status, errorMsg } = state;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValue(name, value);

    // Check input if there is an error
    status === "errors" && inputChecker(name, value);
  };

  const setValue = (name, value) => {
    setState((currForm) => ({
      ...currForm,
      values: {
        ...currForm.values,
        [name]: value,
      },
    }));
  };

  const inputChecker = (name, value) => {
    if (name === "email") {
      setErrorMsg("email", checkValidEmail(value));
    }
    if (name === "password") {
      setErrorMsg("password", checkValidPassword(value));
    }
    if (name === "confirmPassword") {
      setErrorMsg("confirmPassword", checkValidCPassword(password, value));
    }
  };

  const setErrorMsg = (name, msg) =>
    setState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  const setStatus = (stu) =>
    setState((currState) => ({
      ...currState,
      status: stu,
    }));

  // Form data validation
  const validation = () => {
    state.errorMsg = {
      email: checkValidEmail(email),
      password: checkValidPassword(password),
      confirmPassword: checkValidCPassword(password, confirmPassword),
    };
    return checkErrors(state.errorMsg) ? false : true;
  };

  const checkErrors = (errorMsg) => {
    let errors = false;
    Object.values(errorMsg).forEach((value) => {
      if (value) errors = true;
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    if (validation()) {
      setErrorMsg({});
      sendData();
    } else {
      setStatus("errors");
    }
  };

  const sendData = () => {
    // post values to the server
    fetch(`${process.env.REACT_APP_S}/api/create-sub-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: authToken,
      },
      body: JSON.stringify({
        id,
        email,
        firstName,
        lastName,
        password,
      }),
    })
      .then(async (res) => ({
        res,
        data: await res.json(),
      }))
      .then(({ res, data }) => {
        if (res.status === 200) {
          setStatus("success");
          setTimeout(() => {
            navigate("/statistics");
          }, 0);
        } else if (data.error) {
          if (data.email) {
            setState((currState) => ({
              ...currState,
              status: "errors",
              errorMsg: {
                ...currState.errorMsg,
                email: "Email is already exist!",
              },
            }));
            setStatus("errors");
            setErrorLog("Email is already exist!, try login.");
          } else if (data.message) {
            setStatus("errors");
            setErrorLog(data.message);
          }
        } else {
          setStatus("errors");
          setErrorLog("Something went wrong, try again later");
        }
        return res;
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };
  return (
    <section className="home__sign-up fade-in">
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <div className="home__sign-up-heading">
        <h1 className="mb-36">Create new account</h1>
      </div>

      <form className="home__sign-up-form" onSubmit={handleSubmit}>
        <Field
          name="email"
          onChange={handleChange}
          value={email}
          errorMsg={errorMsg}
        />
        <Field
          name="firstName"
          onChange={handleChange}
          value={firstName}
          errorMsg={errorMsg}
        />
        <Field
          name="lastName"
          onChange={handleChange}
          value={lastName}
          errorMsg={errorMsg}
        />
        <Field
          name="password"
          onChange={handleChange}
          value={password}
          errorMsg={errorMsg}
        />
        <Field
          name="confirm password"
          onChange={handleChange}
          value={confirmPassword}
          errorMsg={errorMsg}
        />
        <Button
          variant="primary"
          type="submit"
          disabled={status === "loading" ? true : false}
        >
          {status === "loading" ? <BeatLoader color="#ffffff" /> : "Create"}
        </Button>
      </form>
    </section>
  );
};
export default SignUp;
