import { createContext, useState } from "react";
export const AuthedUserContext = createContext(0);

// just for debugs
AuthedUserContext.displayName = "AuthUser";

const AuthedUserProvider = ({ children }) => {
  const [authedUser, setAuthedUser] = useState(false);
  const [configs, setConfigs] = useState({});

  return (
    <AuthedUserContext.Provider
      value={{ authedUser, setAuthedUser, configs, setConfigs }}
    >
      {children}
    </AuthedUserContext.Provider>
  );
};

export default AuthedUserProvider;
