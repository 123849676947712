import { useEffect, useState, useContext } from "react";
import {
  CheckIcon,
  ExclamationIcon,
  NFCIcon,
  NFCPhoneIcon,
} from "../../helpers/svg";
import Button from "../Button";
import NFCError from "./NFCError";
import { AuthedUserContext } from "../../store/authedUser";

/**
 * NFC component function to write nfc tags
 * @author NFC Egypt <https://nfc.com.eg>
 */
const NFC = ({ setNfcBox }) => {
  const [write, setWrite] = useState("profile");
  const handleWrite = (write) => setWrite(write);
  
  const { authedUser } = useContext(AuthedUserContext);
  const id = authedUser.id;
  const [nfcWrite, setNfcWrite] = useState(
    `${window.location.origin}/card/${id}`
  );
  
  useEffect(() => {
    write === "profile"
      ? setNfcWrite(`${window.location.origin}/card/${id}`)
      : setNfcWrite(`${window.location.origin}/${id}.vcf`);
  }, [id, write]);

  // Ready to Scan
  const [scanBox, setScanBox] = useState(false);
  // Open setScanBox
  const openScanBox = () => {
    setScanBox(true);
    startScan();
  };
  // Close setScanBox
  const closeScanBox = () => {
    setScanBox(false);
    setNfcBox(false);
    setTimeout(() => {
      setScanError("");
      setIsActive(false);
    }, 300);
  };

  // Active
  const [isActive, setIsActive] = useState(false);

  // Scan error
  const [scanError, setScanError] = useState("");

  // ErrorBox
  const [errorBox, setErrorBox] = useState(false);
  // Open errorBox
  const openErrorBox = () => setErrorBox(true);
  // Close errorBox
  // const closeErrorBox = () => setNfcBox(false);
  const closeErrorBox = () => setErrorBox(false);
  // Error msg
  const [errorMsg, setErrorMsg] = useState("");

  // Start Scan
  const startScan = () => {
    if ("NDEFReader" in window) {
      // eslint-disable-next-line no-undef
      const ndef = new NDEFReader();
      ndef
        .write({
          records: [{ recordType: "url", data: nfcWrite }],
        })
        .then(() => {
          setIsActive(true);
          ndef.cancel();
        })
        .catch((error) => setScanError(error.toString()));

      ndef.onerror = (error) => setScanError(`onerror- ${error.toString()}`);
    } else {
      setScanError("NDEFReader API not supported");
    }
  };
  
  return (
    <div className="nfc fade-in-nfc">
      <div className="nfc__scan-wrapper slide-up-nfc">
        <div className="nfc__phone">
          <NFCPhoneIcon />
        </div>
        <div className="nfc__heading">
          Choose Profile Link or VCF File to activate it
        </div>

        <div className="nfc__radio-box">
          <div
            className="nfc__radio-box-item"
            onClick={() => handleWrite("profile")}
          >
            <span
              data-checked={`${write === "profile" ? "true" : "false"}`}
            ></span>
            <span>Write Profile Link</span>
          </div>
          <div
            className="nfc__radio-box-item"
            onClick={() => handleWrite("vcf")}
          >
            <span data-checked={`${write === "vcf" ? "true" : "false"}`}></span>
            <span>Write VCF File</span>
          </div>
        </div>
        <div className="nfc__button">
          <button
            className="nfc__button btn btn--primary"
            onClick={openScanBox}
          >
            Begin Activation
          </button>
          <button
            className="nfc__button-cancel btn"
            onClick={() => setNfcBox(false)}
          >
            Cancel
          </button>
        </div>

        {/* Ready to Scan */}
        <div className={`nfc__rts ${scanBox ? "open" : ""}`}>
          <div className="nfc__rts-wrapper">
            <div className="nfc__rts-heading">
              {isActive ? "Success" : scanError ? "ERROR!" : "Ready to Scan"}
            </div>
            <div
              className={`nfc__rts-icon ${
                isActive ? "active" : scanError ? "error" : ""
              }`}
            >
              {isActive ? (
                <CheckIcon />
              ) : scanError ? (
                <ExclamationIcon />
              ) : (
                <NFCIcon />
              )}
            </div>
            <div className="nfc__rts-instructions">
              {isActive
                ? "Your Card is Activated"
                : scanError
                ? scanError
                : "Hold near your card"}
            </div>
            <div className="nfc__rts-button">
              <Button variant="primary" onClick={closeScanBox}>
                {isActive ? "Ok" : "Cancel"}
              </Button>
            </div>
          </div>
        </div>

        {/* Error */}
        <NFCError
          errorBox={errorBox}
          closeErrorBox={closeErrorBox}
          errorMsg={errorMsg}
        />
      </div>
    </div>
  );
};

export default NFC;
