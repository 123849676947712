export const Error = () => {
  return (
    <div className="err">
      <h1>Something went wrong!</h1>
      <p>
        This page is a general error page that displays when an unexpected error
        occurs on the website.
      </p>
      <a className="btn btn--primary" role="button" href="/">
        Back to home
      </a>
    </div>
  );
};
