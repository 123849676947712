import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BackIcon } from "../helpers/svg";
import Field from "../layout/forms/Field";
import Button from "./Button";
import { checkValidInput } from "../helpers/validation";
import ErrorMsg from "./ErrorMsg";
import { BeatLoader } from "react-spinners";
import RememberMe from "../layout/forms/RememberMe";
import Cookies from "js-cookie";

const SignIn = () => {
  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  const [state, setState] = useState({
    status: "idle",
    errorMsg: {},
    errors: false,
    values: {
      email: "",
      password: "",
    },
  });
  const [rememberMe, setRememberMe] = useState(true);
  const handleChangeRememberMe = () => setRememberMe(!rememberMe);

  const { email, password } = state.values;
  const { status, errorMsg } = state;

  useEffect(() => {
    errorMsg && (errorMsg.email || errorMsg.password || errorMsg.disabled)
      ? setState((currState) => ({
          ...currState,
          errors: true,
        }))
      : setState((currState) => ({
          ...currState,
          errors: false,
        }));
  }, [errorMsg]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValue(name, value);

    // Check input if there is an error
    status === "errors" && inputChecker(name, value);
  };

  const setValue = (name, value) => {
    setState((currForm) => ({
      ...currForm,
      values: {
        ...currForm.values,
        [name]: value,
      },
    }));
  };

  const inputChecker = (name, value) => {
    if (name === "email") {
      setErrorMsg("email", checkValidInput(value, "account"));
    }
    if (name === "password") {
      setErrorMsg("password", checkValidInput(value, "password"));
    }
  };

  const setErrorMsg = (name, msg) =>
    setState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  const setStatus = (stu) =>
    setState((currState) => ({
      ...currState,
      status: stu,
    }));

  // Form data validation
  const validation = () => {
    state.errorMsg = {
      email: checkValidInput(email, "account"),
      password: checkValidInput(password, "password"),
    };
    return checkErrors(state.errorMsg) ? false : true;
  };

  const checkErrors = (errMsg) => {
    let errors = false;
    Object.values(errMsg).forEach((value) => {
      if (value) errors = true;
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");
    if (validation()) {
      setErrorMsg({});
      sendData();
    } else {
      setStatus("errors");
    }
  };

  const sendData = () => {
    fetch(`${process.env.REACT_APP_S}/api/sign-in`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(async (res) => ({
        res,
        data: await res.json(),
      }))
      .then(({ res, data }) => {
        if (res.status === 200) {
          const expires = rememberMe ? 30 : 2 / 24;
          Cookies.set("auth_token", data.token, {
            expires,
            // httpOnly: true,
            secure: true,
          });
          setTimeout(() => {
            setStatus("success");
            window.location.href = `${window.location.origin}/card/${data.id}`;
          }, 0);
        } else if (data.error) {
          if (data.disabled) {
            setState((currState) => ({
              ...currState,
              status: "errors",
              errorMsg: {
                ...currState.errorMsg,
                email: "",
              },
            }));
            setStatus("errors");
            setErrorLog("Unfortunately, your account has been deactivated.");
          }
          if (data.email) {
            setState((currState) => ({
              ...currState,
              status: "errors",
              errorMsg: {
                ...currState.errorMsg,
                email: "The email is incorrect",
              },
            }));
            setStatus("errors");
            setErrorLog("The email is incorrect");
          }
          if (data.password) {
            setState((currState) => ({
              ...currState,
              status: "errors",
              errorMsg: {
                ...currState.errorMsg,
                password: "Wrong password!",
              },
            }));
            setStatus("errors");
            setErrorLog("Wrong password!");
          }
        }
        return res;
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };
  return (
    <section className="home__login fade-in">
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <div className="home__login-heading">
        <Link to="/" aria-label="back">
          <BackIcon />
        </Link>
        <h1>Login</h1>
      </div>
      <p>Welcome Back!</p>

      <form className="home__login-form" onSubmit={handleSubmit}>
        <Field
          name="email"
          onChange={handleChange}
          value={email}
          errorMsg={errorMsg}
        />
        <Field
          name="password"
          onChange={handleChange}
          value={password}
          errorMsg={errorMsg}
        />
        <div className="home__login-option">
          <RememberMe value={rememberMe} onChange={handleChangeRememberMe} />
          <Link to="/forget-password" className="home__login-forgot-password">
            Forget password?
          </Link>
        </div>
        <Button
          variant="primary"
          type="submit"
          disabled={status === "loading" ? true : false}
        >
          {status === "loading" ? <BeatLoader color="#ffffff" /> : "Login"}
        </Button>
      </form>
    </section>
  );
};

export default SignIn;
