import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import StoreProvider from "./store/index";
import Home from "./pages/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateVCard from "./pages/CreateVCard";
import CreateAccount from "./pages/CreateAccount";
import Card from "./pages/Card";
import EditCard from "./pages/EditCard";
import AdminEditCard from "./pages/AdminEditCard";
import UserConfigCard from "./pages/UserConfigCard";
import NotFound from "./pages/404";
import { Error } from "./components/Error";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import Configs from "./pages/Configs";
import Statistics from "./pages/Statistics";

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <Error />,
    children: [
      ...["/", "sign-up", "login", "/forget-password"].map((path) => ({
        path: path,
        element: <Home />,
      })),
      {
        path: "create-vcard",
        element: <CreateVCard />,
      },
      {
        path: "create-account",
        element: <CreateAccount />,
      },
      {
        path: "card/:id",
        element: <Card />,
      },
      {
        path: "card/:id/edit",
        element: <EditCard />,
      },
      {
        path: "admin/card/:id/edit",
        element: <AdminEditCard />,
      },
      {
        path: "admin/card/:id",
        element: <UserConfigCard />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "configs",
        element: <Configs />,
      },
      {
        path: "statistics",
        element: <Statistics />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

document.body.insertAdjacentHTML("afterbegin", "<div id=root></div>");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <StoreProvider>
    <RouterProvider router={router} />
  </StoreProvider>
  // </React.StrictMode>
);
