import React from "react";
import Container from "../layout/Container";

const NotFound = () => {
  return (
    <section className="not-found fade-in">
      <Container>
        <h1>404 Not Found</h1>
        <p className="lead">The page you are looking for does not exist...</p>
      </Container>
    </section>
  );
};

export default NotFound;
