import React from "react";
import {
  AddressRedSqIcon,
  AddressSqIcon,
  EmailRedSqIcon,
  EmailSqIcon,
  FacebookRedSqIcon,
  FacebookSqIcon,
  InstagramRedSqIcon,
  InstagramSqIcon,
  LinkedinRedSqIcon,
  LinkedinSqIcon,
  PhoneRedSqIcon,
  PhoneSqIcon,
  SnapchatRedSqIcon,
  SnapchatSqIcon,
  TelegramRedSqIcon,
  TelegramSqIcon,
  TiktokRedSqIcon,
  TiktokSqIcon,
  TwitterRedSqIcon,
  TwitterSqIcon,
  WebsiteRedSqIcon,
  WebsiteSqIcon,
  WhatsappBusinessRedSqIcon,
  WhatsappBusinessSqIcon,
  WhatsappRedSqIcon,
  WhatsappSqIcon,
  YoutubeRedSqIcon,
  YoutubeSqIcon,
} from "../helpers/svg";
import { usernameToURL, toPascalCase } from "../helpers/functions";

const ThemeIcons = ({ contact, authedUser, red }) => {
  return (
    <ul className="card-profile__contacts-tm2">
      {contact.phones.length
        ? contact.phones.map((phone, index) =>
            !phone.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : phone.number && (
                  <li key={index} className="card-profile__contacts-tm2-item">
                    <a
                      href={`tel:${phone.number}`}
                      className="card-profile__contacts-tm2-item-link"
                    >
                      <span className="card-profile__contacts-tm2-item-icon">
                        {red ? <PhoneRedSqIcon /> : <PhoneSqIcon />}
                      </span>
                      <span className="card-profile__contacts-tm2-item-name">
                        {toPascalCase(
                          phone.type === "HOME"
                            ? "PERSONAL"
                            : phone.type === "WORK"
                            ? "BUSINESS"
                            : phone.type
                        )}
                        {/* {`phone ${index ? index + 1 : ""}`} */}
                      </span>
                    </a>
                  </li>
                )
          )
        : null}

      {contact.emails.length
        ? contact.emails.map((email, index) =>
            !email.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : email.email && (
                  <li key={index} className="card-profile__contacts-tm2-item">
                    <a
                      href={`mailto:${email.email}`}
                      className="card-profile__contacts-tm2-item-link"
                    >
                      <span className="card-profile__contacts-tm2-item-icon">
                        {red ? <EmailRedSqIcon /> : <EmailSqIcon />}
                      </span>
                      <span className="card-profile__contacts-tm2-item-name">
                        {toPascalCase(
                          email.type === "HOME"
                            ? "PERSONAL"
                            : email.type === "WORK"
                            ? "BUSINESS"
                            : email.type
                        )}
                        {/* {`email ${index ? index + 1 : ""}`} */}
                      </span>
                    </a>
                  </li>
                )
          )
        : null}

      {contact.websites.length
        ? contact.websites.map((website, index) => {
            if (website.url && !website.url.includes("http")) {
              website.url = `https://${website.url}`;
            }
            return !website.public &&
              (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : website.url && (
                  <li key={index} className="card-profile__contacts-tm2-item">
                    <a
                      href={website.url}
                      target="_blank"
                      rel="noreferrer"
                      className="card-profile__contacts-tm2-item-link"
                    >
                      <span className="card-profile__contacts-tm2-item-icon">
                        {red ? <WebsiteRedSqIcon /> : <WebsiteSqIcon />}
                      </span>
                      <span className="card-profile__contacts-tm2-item-name">
                        {`website ${index ? index + 1 : ""}`}
                      </span>
                    </a>
                  </li>
                );
          })
        : null}

      {contact.addresses.length
        ? contact.addresses.map((address, index) =>
            !address.public &&
            (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : (address.address ||
                  address.city ||
                  address.country ||
                  address.zip) && (
                  <li
                    key={index}
                    className="card-profile__contacts-tm2-item adr"
                  >
                    <a
                      href={`https://www.google.com/maps?q="${`${address.address}, ${address.city}, ${address.country}, ${address.zip}`}`}
                      target="_blank"
                      rel="noreferrer"
                      className="card-profile__contacts-tm2-item-link"
                    >
                      <span className="card-profile__contacts-tm2-item-icon">
                        {red ? <AddressRedSqIcon /> : <AddressSqIcon />}
                      </span>
                      <span className="card-profile__contacts-tm2-item-name">
                        {`address ${index ? index + 1 : ""}`}
                      </span>
                    </a>
                  </li>
                )
          )
        : null}

      {contact.socialMediaLinks.length
        ? contact.socialMediaLinks.map((link, index) => {
            const type = link.type;
            const url = usernameToURL(type, link.url);
            return !link.public &&
              (!authedUser || (authedUser && authedUser.id !== contact.id))
              ? null
              : url && (
                  <li key={index} className="card-profile__contacts-tm2-item">
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="card-profile__contacts-tm2-item-link"
                    >
                      <span className="card-profile__contacts-tm2-item-icon">
                        {type === "FACEBOOK" ? (
                          red ? (
                            <FacebookRedSqIcon />
                          ) : (
                            <FacebookSqIcon />
                          )
                        ) : type === "INSTAGRAM" ? (
                          red ? (
                            <InstagramRedSqIcon />
                          ) : (
                            <InstagramSqIcon />
                          )
                        ) : type === "WHATSAPP" ? (
                          red ? (
                            <WhatsappRedSqIcon />
                          ) : (
                            <WhatsappSqIcon />
                          )
                        ) : type === "WA BUSINESS" ? (
                          red ? (
                            <WhatsappBusinessRedSqIcon />
                          ) : (
                            <WhatsappBusinessSqIcon />
                          )
                        ) : type === "TWITTER" ? (
                          red ? (
                            <TwitterRedSqIcon />
                          ) : (
                            <TwitterSqIcon />
                          )
                        ) : type === "TIKTOK" ? (
                          red ? (
                            <TiktokRedSqIcon />
                          ) : (
                            <TiktokSqIcon />
                          )
                        ) : type === "YOUTUBE" ? (
                          red ? (
                            <YoutubeRedSqIcon />
                          ) : (
                            <YoutubeSqIcon />
                          )
                        ) : type === "LINKEDIN" ? (
                          red ? (
                            <LinkedinRedSqIcon />
                          ) : (
                            <LinkedinSqIcon />
                          )
                        ) : type === "TELEGRAM" ? (
                          red ? (
                            <TelegramRedSqIcon />
                          ) : (
                            <TelegramSqIcon />
                          )
                        ) : type === "SNAPCHAT" ? (
                          red ? (
                            <SnapchatRedSqIcon />
                          ) : (
                            <SnapchatSqIcon />
                          )
                        ) : red ? (
                          <WebsiteRedSqIcon />
                        ) : (
                          <WebsiteSqIcon />
                        )}
                      </span>
                      <span className="card-profile__contacts-tm2-item-name">
                        {type.toLowerCase()}
                      </span>
                    </a>
                  </li>
                );
          })
        : null}
    </ul>
  );
};

export default ThemeIcons;
